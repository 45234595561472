import { useMediaQuery } from "@vueuse/core";

export function useCustomBreakpoints(device) {
  const breakpointsMap = new Map([
    ["for-phone-only", "(max-width: 599px)"],
    ["for-tablet-portrait-up", "(min-width: 600px)"],
    ["for-tablet-landscape-up", "(min-width: 900px)"],
    ["for-desktop-up", "(min-width: 1200px)"],
  ]);

  return useMediaQuery(breakpointsMap.get(device) || "");
}
